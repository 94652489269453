import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectHeading = makeShortcode("ProjectHeading");
const ProjectSection = makeShortcode("ProjectSection");
const ProjectInfo = makeShortcode("ProjectInfo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ProjectHeading title='Taskforce Wildlife by United for Wildlife' abstract='Supporting transportation companies with becoming more resilient to the exploitation caused by illegal wildlife traffickers ' borderBottom huge mdxType="ProjectHeading" />
    <ProjectSection mdxType="ProjectSection">
  <ProjectInfo title='Timeline' abstract='Launched Fall 2018' mdxType="ProjectInfo" />
  <ProjectInfo title='Company' abstract='Edenspiekermann' mdxType="ProjectInfo" />
  <ProjectInfo title='Role' abstract='I designed and iterated on core experiences within the application while reporting directly to the technical director of the product.' mdxType="ProjectInfo" />
  <ProjectInfo title='About' abstract='Taskforce Wildlife is a tool that enables companies in the transport sector to assess their logistical decisions and confidently plan safe routes.' mdxType="ProjectInfo" />
    </ProjectSection>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdQVCo//xAAXEAADAQAAAAAAAAAAAAAAAAAAARAR/9oACAEBAAEFAjGq5//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAAQAREy/9oACAEBAAY/AjVuF//EABsQAQACAgMAAAAAAAAAAAAAAAEAERAxIUGx/9oACAEBAAE/IXUKtq52HGHTPDH/2gAMAwEAAgADAAAAEHff/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERIUGhMf/aAAgBAQABPxBoZ7BSqmocI8OQZ6KP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The desktop interface of Taskforce Wildlife.",
          "title": "The desktop interface of Taskforce Wildlife.",
          "src": "/static/ed055aaa8bfb07bafa088014646e5a76/040c5/taskforce-wildlife-hero.jpg",
          "srcSet": ["/static/ed055aaa8bfb07bafa088014646e5a76/85449/taskforce-wildlife-hero.jpg 300w", "/static/ed055aaa8bfb07bafa088014646e5a76/f6133/taskforce-wildlife-hero.jpg 600w", "/static/ed055aaa8bfb07bafa088014646e5a76/040c5/taskforce-wildlife-hero.jpg 1200w", "/static/ed055aaa8bfb07bafa088014646e5a76/12e8d/taskforce-wildlife-hero.jpg 1800w", "/static/ed055aaa8bfb07bafa088014646e5a76/8117b/taskforce-wildlife-hero.jpg 2122w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`The desktop interface of Taskforce Wildlife.`}</figcaption>{`
  `}</figure>
    <h2>{`Context`}</h2>
    <p>{`Organized criminal networks across the globe have caused an unprecedented increase in the illegal hunting and trading of animals. Traffickers exploit unsuspecting transport businesses, such as passenger and cargo airlines and shipping companies, by using them to smuggle their contraband.`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAp0lEQVQY04WQgQqDMAxE/f9PdFDtZiuIWju0tVV7IwFHhxsLHOGO5pGmSCkh13Ecb5GnyvN/74rTLMuCvu+x7ztijKx1XdG2LeZ5/sinaUKIEdu2sad+AZpxxK0suZNCCHDOcdZ1HYZhgLWWh+u6htaaM2PMd+DTWgghUIkKWil47+GdQ/NoIKXEXUoG0DBtTdBKCCileMsLML8FFX0xv9OvW57ZCXwBUcyE1vAkmvcAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The current state of wildlife trafficking",
          "title": "The current state of wildlife trafficking",
          "src": "/static/0e08f86166ffd2ca1486d522d8a4d468/7dc98/taskforce-wildlife-problem.png",
          "srcSet": ["/static/0e08f86166ffd2ca1486d522d8a4d468/fabc2/taskforce-wildlife-problem.png 300w", "/static/0e08f86166ffd2ca1486d522d8a4d468/dadad/taskforce-wildlife-problem.png 600w", "/static/0e08f86166ffd2ca1486d522d8a4d468/7dc98/taskforce-wildlife-problem.png 1200w", "/static/0e08f86166ffd2ca1486d522d8a4d468/5dce9/taskforce-wildlife-problem.png 1500w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`The current state of wildlife trafficking`}</figcaption>{`
  `}</figure>
    <p>{`In response to the increase of illegal wildlife activities, `}<a parentName="p" {...{
        "href": "https://royalfoundation.com/programme/private-sector/"
      }}>{`United for Wildlife`}</a>{` (UfW) approached my team at Edenspiekermann and asked us to explore approaches to slow the rate of wildlife trafficking.`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1063px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "41%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB80lEQVQoz3WR20uTARiHveg+gkAi74IuCuwiDYeredhWmdpaB4dIFNRNRJQ1NvOwXGzM5dApbsvUza38Ypo53XRs2jLN2UmSsBA7ULIg6D/Y9j3RWFSU783Le/F7eF5+OaIokhZFfs67z19ZXP3CTHyJ4EyMt4nvjEYirLxfZT4+y+R0mLX1BEMPA/S1NnGh5iSTU5FMNpVKZXZOBphOZ45o/AUDo0E8JiOC3UXPgJuuXgfOXjPXG69gshhwCgLbigvQHCikLG8rguDLApO/gWLW8OP6N6JPn/N61E+/vgGd9iqOLjMnjpRw6Kgao0lPT7ueg5J9yPZLOKcuZWzEs7Hhs4VZYtMRwo8W0V2+SHvLJe47btB67TzVKjl1xyrw2Vo4U1FOlVpJlaYcv8/xr+Ev4KvZKZ5EJhgeD3Onf5CXUwLLsSC3bjZQe0pGdWUx0lIpx2tVVKoVyAp24uqwZrLJ5J8vZ4Ef3iwRGBlCp9eiN1oIB4ZZmQvS1qylUi5BpZSyK38HyhoFp8+qKNqzHa/XvrHh8sJj3E4bZQo5ak0dbc1NhPq6sRv1yEsl7C3azZbczUgk+RwuKSQvdxOdHYb/GGZLSXxaY3EuSp9nEM89P/HQA0JeN/MTIe66bmNurMdmNdHd1YnVZMJmMRAaH/urlB8j79+i9J9n+QAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "United for Wildlife, an initiative led by the Duke of Cambridge to stop wildlife, approached our team us for possible solutions to lower the rate of wildlife trafficking.",
          "title": "United for Wildlife, an initiative led by the Duke of Cambridge to stop wildlife, approached our team us for possible solutions to lower the rate of wildlife trafficking.",
          "src": "/static/f3e16a5bce048685e405fc3d4c608d00/aac98/taskforce-wildlife-UFW.png",
          "srcSet": ["/static/f3e16a5bce048685e405fc3d4c608d00/fabc2/taskforce-wildlife-UFW.png 300w", "/static/f3e16a5bce048685e405fc3d4c608d00/dadad/taskforce-wildlife-UFW.png 600w", "/static/f3e16a5bce048685e405fc3d4c608d00/aac98/taskforce-wildlife-UFW.png 1063w"],
          "sizes": "(max-width: 1063px) 100vw, 1063px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`United for Wildlife, an initiative led by the Duke of Cambridge to stop wildlife, approached our team us for possible solutions to lower the rate of wildlife trafficking.`}</figcaption>{`
  `}</figure>
    <h2>{`Understanding wildlife trafficking`}</h2>
    <p>{`Due to the complexity of the problem, we looked for ways to pare it down to bite-sized pieces to tighten the scope. We conducted user interviews with the actors involved.`}</p>
    <p>{`In the interview with the logistics team from Bangkok Airways, we learned transport businesses often unwittingly become conduits to illegal wildlife trading. To combat this, the logistics team aimed to help transport vehicles avoid compromised areas. Before a logistician plans a transport route, they attempt to track and analyse historic trafficking reports on the map.`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "19.333333333333332%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtElEQVQY031PWwqDMBD09N7Bnkc8gx9WLQYEYxTJQ9M8puy2hX41MDBLZmd2inme4ZwDvZwz/Hnicg4pJcQY/yKE8OYhIMXIHkVZlhDTxINSCqppsNc1NilhreWwdV2htWZ+HAeMMcxJb68LehyxtS0yGd6qCveu4yQhBLL3eJKJlHwlXSGXBd57bkAa4vQ3DgOb5xjx6Hvs+47CWcup3wqUQqAFMiD8ctZ8ODWgPdJrYzjoBdLYM2SAg7hhAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The current journey map of transport businesses",
          "title": "The current journey map of transport businesses",
          "src": "/static/360d038fdec63fe10e4ed0ab53ed68f3/7dc98/taskforce-wildlife-transport-businesses-journey.png",
          "srcSet": ["/static/360d038fdec63fe10e4ed0ab53ed68f3/fabc2/taskforce-wildlife-transport-businesses-journey.png 300w", "/static/360d038fdec63fe10e4ed0ab53ed68f3/dadad/taskforce-wildlife-transport-businesses-journey.png 600w", "/static/360d038fdec63fe10e4ed0ab53ed68f3/7dc98/taskforce-wildlife-transport-businesses-journey.png 1200w", "/static/360d038fdec63fe10e4ed0ab53ed68f3/f6bd6/taskforce-wildlife-transport-businesses-journey.png 1745w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`The current journey map of transport businesses`}</figcaption>{`
  `}</figure>
    <p>{`To understand the workflow of UfW wildlife rangers, we learned that they would make reports when they detect any suspicious activity. But these reports would often get buried in a dense backlog, rarely reaching transport businesses in time if at all.`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "18.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAh0lEQVQY042Ouw6DMAxF+f89H5H/QGJlZMtURaDyaIgjROzcKglDl5Za8mBd36PTGGPgnEOeJIJAhBgjRATMDD5PCHPNUyr7axqlFKy15ZiHAXPbwm8blnWF9x6vvsej68BX4RaotUa2zDbPcYQcRzUNoRrFiGWaSv4XcHc7iOjrw2f9DpaBb+eSN5i2Kc2UAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The current journey map of United for Wildlife and their wildlife rangers",
          "title": "The current journey map of United for Wildlife and their wildlife rangers",
          "src": "/static/de3b1a9af3e2784e646322ce35ee06cb/7dc98/taskforce-wildlife-UFW-journey.png",
          "srcSet": ["/static/de3b1a9af3e2784e646322ce35ee06cb/fabc2/taskforce-wildlife-UFW-journey.png 300w", "/static/de3b1a9af3e2784e646322ce35ee06cb/dadad/taskforce-wildlife-UFW-journey.png 600w", "/static/de3b1a9af3e2784e646322ce35ee06cb/7dc98/taskforce-wildlife-UFW-journey.png 1200w", "/static/de3b1a9af3e2784e646322ce35ee06cb/59ab2/taskforce-wildlife-UFW-journey.png 1800w", "/static/de3b1a9af3e2784e646322ce35ee06cb/234eb/taskforce-wildlife-UFW-journey.png 1865w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`The current journey map of United for Wildlife and their wildlife rangers`}</figcaption>{`
  `}</figure>
    <h3>{`Key insights from logisticians`}</h3>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "18.999999999999996%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAf0lEQVQY041P7QrDIAzs+79kp4jRKp2dIv5QryQwWBkrC4R83OWSLAAw58Sd3eGMvXGOCydjDHjvsXkPsgTnnNQcn/uO3jtijAghwBgDIgJZK71aq8x/CaaUhKi1lkEms7+OQwRLKbLgsa5QSgk354zWmuAXwX9e/sXhYz5fPgEvmTfAYiDixQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Some key insights I gleaned from our interview with the logisticians from Bangkok Airways",
          "title": "Some key insights I gleaned from our interview with the logisticians from Bangkok Airways",
          "src": "/static/13f244b8a5f31d85553ba12c7e8cf14e/7dc98/taskforce-wildlife-logistician-pain-points.png",
          "srcSet": ["/static/13f244b8a5f31d85553ba12c7e8cf14e/fabc2/taskforce-wildlife-logistician-pain-points.png 300w", "/static/13f244b8a5f31d85553ba12c7e8cf14e/dadad/taskforce-wildlife-logistician-pain-points.png 600w", "/static/13f244b8a5f31d85553ba12c7e8cf14e/7dc98/taskforce-wildlife-logistician-pain-points.png 1200w", "/static/13f244b8a5f31d85553ba12c7e8cf14e/92d1e/taskforce-wildlife-logistician-pain-points.png 1435w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`Some key insights I gleaned from our interview with the logisticians from Bangkok Airways`}</figcaption>{`
  `}</figure>
    <h3>{`Key insights from rangers`}</h3>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "18.999999999999996%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAdklEQVQY063PuQ7DIBAEUP//TxoHFpaz4OiAscBlEilFtpmRRnrSHgAw58S3W9uv+8pjld47YoxwzoGUgmWG9x7MjFrq3qWUMMZAE4GIoLWGtRatNYwx3sFSygbEee5MKW201gfMOW9QCIHXdUFJiRDCZ/CfL98Jsjd8LOeXVgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Some key insights about rangers I gleaned from our interview with the UfW",
          "title": "Some key insights about rangers I gleaned from our interview with the UfW",
          "src": "/static/2a17fd4e8ddc4ff264bc26d1efe67e98/7dc98/taskforce-wildlife-ranger-pain-points.png",
          "srcSet": ["/static/2a17fd4e8ddc4ff264bc26d1efe67e98/fabc2/taskforce-wildlife-ranger-pain-points.png 300w", "/static/2a17fd4e8ddc4ff264bc26d1efe67e98/dadad/taskforce-wildlife-ranger-pain-points.png 600w", "/static/2a17fd4e8ddc4ff264bc26d1efe67e98/7dc98/taskforce-wildlife-ranger-pain-points.png 1200w", "/static/2a17fd4e8ddc4ff264bc26d1efe67e98/92d1e/taskforce-wildlife-ranger-pain-points.png 1435w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`Some key insights about rangers I gleaned from our interview with the UfW`}</figcaption>{`
  `}</figure>
    <h2>{`Problem`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Long-term goal`}</strong>{`: To slow the rate of wildlife trafficking`}</li>
      <li parentName="ul"><strong parentName="li">{`Short-term goal`}</strong>{`: To help transport businesses `}<strong parentName="li">{`outmaneuver wildlife traffickers`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Opportunity`}</strong>{`: How can we help businesses `}<strong parentName="li">{`leverage the intel of rangers to avoid traffickers`}</strong>{` en route?`}</li>
    </ul>
    <h2>{`Explorations`}</h2>
    <p>{`I searched for precedents in the navigation domain for ideas that would rectify the anxiety of logisticians. My favorite was `}<a parentName="p" {...{
        "href": "https://www.tomtom.com/products/real-time-traffic/"
      }}>{`TomTom’s Traffic`}</a>{`, a tool that visualizes patterns of traffic congestion for drivers by analyzing historical traffic patterns for roads over time.`}</p>
    <p>{`I wondered if we could bring the analysis of real-time reports to predict trafficking before it happens. In this early exploration, I ideated a tool that used existing risk reports to predict the safest and fastest routes. However, this idea required our tech team to amalgamate the technology behind each logistic solution used by logisticians. This wasn’t feasible for the scope of the project so I tabled the idea.`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9klEQVQoz6XSS0/CUBCGYf7/TzHRBUrQJiC4IhGtWFTS00NbDJTL6eWUtloTeI1NNC4MiWWSySzmy7OZafBH7ff7auZ5QRTHFEVedZZllGX5k/nO/a7GIXCxXPEymeD7LsKxq5kkcX1Qa40tBL7vMbIs7s0RaxXWB7Nsy3K1ItGaj/cSKQSOtKvdbrf7P5huNXPP41VI1EaxCGbIqVMfjJMIz5W4U8FwfMfN4Bbh+vVBpRRPz2P6gx5n7TanLQPnGPDrouajxYXR4rJ7TfOqexwYRgrzYUinc47R63PSbGNLtz5YFG8Ec5dQBQTrDdKbkaTpwbf5BNE0BY2V8gT8AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "I ideated a tool that used existing risk reports to predict the safest and fastest routes",
          "title": "I ideated a tool that used existing risk reports to predict the safest and fastest routes",
          "src": "/static/df84727b06a78c135a61ee69fc7fd489/7dc98/taskforce-wildlife-explorations.png",
          "srcSet": ["/static/df84727b06a78c135a61ee69fc7fd489/fabc2/taskforce-wildlife-explorations.png 300w", "/static/df84727b06a78c135a61ee69fc7fd489/dadad/taskforce-wildlife-explorations.png 600w", "/static/df84727b06a78c135a61ee69fc7fd489/7dc98/taskforce-wildlife-explorations.png 1200w", "/static/df84727b06a78c135a61ee69fc7fd489/47614/taskforce-wildlife-explorations.png 1688w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`I ideated a tool that used existing risk reports to predict the safest and fastest routes`}</figcaption>{`
  `}</figure>
    <h2>{`Solution`}</h2>
    <p>{`I looked for ideas that would align with our tech constraints. I was specifically interested in the way analytic and forecasting tools made data actionable for users. I wondered if there was a way to achieve this by aggregating reports that are specific to a logistician’s route. Once again referencing notes from our Bangkok Airways interview, my tech director and I established a set of basic parameters needed from a route plan to assess the projected risk. I created an experience that allowed logisticians to input a route to see if it is safe.`}</p>
    <h3>{`How Taskforce Wildlife works:`}</h3>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABXUlEQVQoz42Ty26DQAxF8/FddZUP6KaL9ouySEurJCUXyBBgeA3zhMpOqKKoUkCyZjDysa9tVuM4YrZpmuC9xzAM0FrzSWathXPuzzef5KeYW8bqHkiBUkoURYG2bTEohUZWCOPI35RSbMYYGG0eAym7EAJ5nqNpGvR9D2ctw6MoAj1pmvL7PexfIGXe7/c4Ho8Mm+WSxCRJOKF1FmbQCM4vkxzHMRKA73NfCUxV13XNkq02y4AUKHLBcruuQ9e2F6CxLDfLMvRdh1717F9UYXKIIZKUgSQ1hMCJsjRFcT7zoFS/EEg9LE4CZZ5DXyep1QAfAkRZ4FxL6ODYwjXm4ZS3H1tsNhsejixLWO+hd0D89AKs35Cu35E8v8Ik+QUYHlSYpRlO2YnXo6oq+DFAyRrl9hvN1w/k5w51dIBfsocEpEnSnlEP6R7o79EaZdtA9h0G7+AINE2YKPYG+gtlMUADbx0JGQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": " ",
          "title": " ",
          "src": "/static/9a2e5fa45b13df97478a21f34d88cad0/7dc98/taskforce-wildlife-how-it-works-1.png",
          "srcSet": ["/static/9a2e5fa45b13df97478a21f34d88cad0/fabc2/taskforce-wildlife-how-it-works-1.png 300w", "/static/9a2e5fa45b13df97478a21f34d88cad0/dadad/taskforce-wildlife-how-it-works-1.png 600w", "/static/9a2e5fa45b13df97478a21f34d88cad0/7dc98/taskforce-wildlife-how-it-works-1.png 1200w", "/static/9a2e5fa45b13df97478a21f34d88cad0/47614/taskforce-wildlife-how-it-works-1.png 1688w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{` `}</figcaption>{`
  `}</figure>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVQoz41STU+EMBT0n3syMXow8WI8GPVg4sXfs2Q/KrvsLLCwS2mhfLQF88qyMTFxOUxemTDzZppedV2HEX3fwxiDuq4dqqpyaNsWWuszN07iSfPb4+qPodbQahAppQajphl4UaAqlfsmvmmay4a0NQgChGEIIcRZVJYlVowhXDBkiCAKCWvthITGOEPOObIsQxRFbgmlJT7Z76FkgVJKmFZfNqStSZLgcDi4e8rz3NWjM2MMcRzjcDxCcA6rzbSEvu87YZqmLhUlJMPZbObgeR52YTitMv1ENSlZzjnSJHEJqfJquQK2W0RhBJHxC5X7Hr2bHSydT+hOs2kb8LJA21kYamItrJmQUHOJ6OED8eMn9s9f2N28oJqvUW9jfF8/YXP3huD+HcHtK+pdMhjafwxrpXD0GPK578A9hrYoUQmJzGOQyw3EYg2x3MDUE94hvTteSFeP0HRDraqucJQCuRo4PV7JSTca/gB+6jv7QpzApAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "I created an experience that allowed logisticians to input a route to see if it is safe",
          "title": "I created an experience that allowed logisticians to input a route to see if it is safe",
          "src": "/static/4eb766de4739d7580f74b115ecab643d/7dc98/taskforce-wildlife-how-it-works-2.png",
          "srcSet": ["/static/4eb766de4739d7580f74b115ecab643d/fabc2/taskforce-wildlife-how-it-works-2.png 300w", "/static/4eb766de4739d7580f74b115ecab643d/dadad/taskforce-wildlife-how-it-works-2.png 600w", "/static/4eb766de4739d7580f74b115ecab643d/7dc98/taskforce-wildlife-how-it-works-2.png 1200w", "/static/4eb766de4739d7580f74b115ecab643d/47614/taskforce-wildlife-how-it-works-2.png 1688w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`I created an experience that allowed logisticians to input a route to see if it is safe`}</figcaption>{`
  `}</figure>
    <h2>{`Design Guidelines`}</h2>
    <p>{`Unlike many products on the market today, we knew a wildlife product needed to work with specific real-world constraints. For this reason, we established two major guidelines.`}</p>
    <h3>{`Guideline #1: Prompt action without fear-mongering`}</h3>
    <p>{`The tool needed to present critical information and a ton of it. However, we knew that jampacking the tool with terrifying warnings and complicated jargon would only overwhelm transport businesses. The design needed to strike a balance between approachable and actionable without downplaying the severity or urgency of the issue.`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3klEQVQoz52SWQqEQAxEvf9pBPUO6j3qQ3HvxaVdhgo0iDiOzEfATifVrxKDfd9xjeM4JM7f/nzNXe+DO0HfNM8ziqJAVVXQWkt+XVfJO+ewbZucGb7nUZCFxhgopWCtxbIsctd1HcqyxDAMsMZKDWt/Ck7ThKZphMZTkOqb7Z+WveA4jhJnwrquZQyke01IMlqlmLdMQo6gbVsRFHr3coaekEvwi2D8vWUS3QmQjg+RVCslDl4RclZRFCGOYyRJgjAM5TeiwND3IkgX50U9CrIhyzLkeS6RpqnQPVn+ANFH+jCwDnfSAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "To ensure logisticians are empowered and not overwhelmed, the risk report is separated into 3 sections: summary, what to Know and what to do.",
          "title": "To ensure logisticians are empowered and not overwhelmed, the risk report is separated into 3 sections: summary, what to Know and what to do.",
          "src": "/static/0b4eaa5684efdcbc07e364752d87b2a6/7dc98/taskforce-wildlife-prompt-action.png",
          "srcSet": ["/static/0b4eaa5684efdcbc07e364752d87b2a6/fabc2/taskforce-wildlife-prompt-action.png 300w", "/static/0b4eaa5684efdcbc07e364752d87b2a6/dadad/taskforce-wildlife-prompt-action.png 600w", "/static/0b4eaa5684efdcbc07e364752d87b2a6/7dc98/taskforce-wildlife-prompt-action.png 1200w", "/static/0b4eaa5684efdcbc07e364752d87b2a6/47614/taskforce-wildlife-prompt-action.png 1688w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`To ensure logisticians are empowered and not overwhelmed, the risk report is separated into 3 sections: summary, what to Know and what to do.`}</figcaption>{`
  `}</figure>
    <h3>{`Guideline #2: Provide accessibility`}</h3>
    <p>{`Users needed to be able to access Taskforce Wildlife while patrolling rural areas with limited technological access and send reports in real time. It needed to be lightweight, yet reliable. The design system needed to lean enough that loading time would not be compromised.`}</p>
    <p>{`I worked closely with the technical director to develop a library of responsive components fluid across all screen sizes and browser versions. We also opted for typography and high contrast visual treatments that would be communicative in smaller and older devices.`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "28.333333333333332%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA50lEQVQY012QS1LDMBBEff8KW/awghNwGwKyOv5JGn1tnIWLmokdFBZdkkatNz1qhmGA1hpt28rK8t4jxogQgqyslNK9dtRTdT7UMKjve1hrYYyBcw7jOAq0NrLHTNO9gSOHwUyIOe9KNyB5DyLCPM/4WRbE4AXGidZ1FfGem3GdPct1hftUOD+9QD+/Q51ecXn7QEwJjbdOUjE0hgDvSR5z8lIKtm0TYD0mPwyOQF8a9A3QuQXpTu6bvuvgrJUDw8OemMfmRjnnB2D9h3Eufyr5NnJg0/4vh5FhSikRw2tgrLz/xQl/AYFouYlx1qpDAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "I worked closely with the technical director to create a lean library of less than 10 responsive components that was screen and browser agnostic.",
          "title": "I worked closely with the technical director to create a lean library of less than 10 responsive components that was screen and browser agnostic.",
          "src": "/static/85fda3274546fb79ba6f7953ef356bc4/7dc98/taskforce-wildlife-components.png",
          "srcSet": ["/static/85fda3274546fb79ba6f7953ef356bc4/fabc2/taskforce-wildlife-components.png 300w", "/static/85fda3274546fb79ba6f7953ef356bc4/dadad/taskforce-wildlife-components.png 600w", "/static/85fda3274546fb79ba6f7953ef356bc4/7dc98/taskforce-wildlife-components.png 1200w", "/static/85fda3274546fb79ba6f7953ef356bc4/59ab2/taskforce-wildlife-components.png 1800w", "/static/85fda3274546fb79ba6f7953ef356bc4/e93ee/taskforce-wildlife-components.png 2016w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`I worked closely with the technical director to create a lean library of less than 10 responsive components that was screen and browser agnostic.`}</figcaption>{`
  `}</figure>
    <h3>{`Impact`}</h3>
    <p>{`Taskforce Wildlife has since garnered corporate buy-in from numerous businesses in the transport sector, including Virgin Atlantic. In late 2018, service was released and is used by thousands of rangers and logisticians across the globe. Our client, United for Wildlife reported back with very positive feedback from users. Additionally, this project was featured in a presentation led by the Duke of Cambridge at the 2018 Illegal Wildlife Trade Conference.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      